body.dermatest {
    font-family: Calibri, Arial;
    font-weight: 250;
    color: #222222;
    line-height: 170%;
}

/* grid system ****************/

.row-center {
    align-items: center;
}

/* headers ****************/

.dermatest h2 {
    font-style: normal;
    font-weight: 250;
    font-size: 2em;
}
.dermatest h3 {
    font-style: normal;
    font-weight: 250;
    font-size: 1.6em;
}
.dermatest h4 {
    font-weight: 250;
    font-size: 1.2em;
}
.dermatest h5 {
    font-weight: 250;
    font-size: 1.1em;
}
.dermatest h6 {
    font-weight: 250;
    font-size: 1.0em;
}

/* sizing ****************/

.dermatest .w-10 {
    width: 10% !important;
}
.dermatest .w-20 {
    width: 20% !important;
}
.dermatest .w-40 {
    width: 40% !important;
}

/* typography ****************/

.dermatest b, .dermatest strong {
    font-weight: bold;
}

/* buttons, links ****************/

.dermatest .btn-link {
    color: #1891ca;
    text-decoration: none;
}
.dermatest .btn-link.valid {
    color: #33aa33;
}
.dermatest a:hover, .dermatest .btn-link:hover {
    color: #d0d0d0;
}

.dermatest tr.table-active a:hover, .dermatest tr.table-active .btn-link:hover {
    color: #a0a0a0;
}

.dermatest .btn {
    border-radius: 0;
}
.dermatest .btn:focus {
    box-shadow: 0 0 0 .25rem rgba(13,110,253,.25);
}

.dermatest .btn-primary {
    background-color: #1891ca !important;
    color: white !important;
}

.dermatest .btn-outline-primary {
    border-color: #1891ca !important;
    color: #1891ca !important;
}

.dermatest .btn-outline-primary:hover {
    background-color: #1891ca !important;
    color: white !important;
}

.dermatest .btn.small {
    font-size: 0.75em;
    padding-bottom: 0;
    padding-top: 0;
}

/* navigation *************/

.is-sticky {
    z-index: 10;
}
.dermatest .search-box {
    z-index: 20;
}

.dermatest .navbar-sticky, .dermatest .navbar-wrapper {
    height: 6.7em;
}

.dermatest .navbar-sticky {
    background-color: #1891ca;
    color: white !important;
    box-shadow: rgba(0, 0, 0, 0.4) 1px 5px 6px -3px;
    width: 100%;
    z-index: 10;
}

.dermatest header.staging .navbar-sticky {
    background-color: #555;
}

.dermatest .navbar {
    padding: 1em;
}

.dermatest .navbar-light .navbar-nav .nav-link {
    color: white;
    cursor: pointer;
}
.dermatest .navbar-light .navbar-nav .nav-link:hover {
    color: #222222;
}
.dermatest .navbar-brand {
    display: inline-block;
    font-size: 0.8em;
    color: white;
    cursor: pointer;
}
.dermatest .navbar-brand .capital {
    font-size: 2em;
    font-weight: bold;
    color: white;
}

.dermatest .navbar-logo {
    font-size: 3rem;
    margin-left: 5em;
}

.dermatest .glyph-icon {
    vertical-align: sub;
    font-size: 2.0em;
}

.dermatest .navbar-logo .glyph-icon {
    color: white;
}

.dermatest main > .container-fluid {
    padding: 0;
    background-color: white;
}
.dermatest .nav-link {
    color: #1891ca;
    display: inline-block;
}
.nav .nav-item {
    width: 8em;
}
.nav .nav-item .nav-link {
    padding-left: 0;
    display: block;
}

.nav .nav-item .nav-link.active {
    font-weight: bold;
}

main {
    background-color: white;
    padding: 0.2em 0 1em 0!important;
}

.dermatest .side-nav {
    top: 115px;
}

/* tables ************/

.dermatest .table {
    border-left: 1px solid rgba(0,0,0,.1);
    border-right: 1px solid rgba(0,0,0,.1);
    border-bottom: 1px solid rgba(0,0,0,.1);
}

.dermatest .table td, .table th {
    vertical-align: top;
    padding: .3em;
}
.dermatest .table td a {
    padding: 0;
}

.dermatest .table table {
    width: 100%;
}

.dermatest .table td > p:last-child {
    margin-bottom: 0;
}

.dermatest .table-size {
    font-weight: bold;
    font-size: 1.2em;
    padding-top: 0.3em;
    color:rgba(0, 0, 0, 0.3);
}

.dermatest .table-striped tr.table-active > * {
    /* Bootstrap bug: regular striped bg gets higher prio than active bg */
    --bs-table-accent-bg: var(--bs-table-active-bg);
}

.dermatest .table tr.even > * {
    background-color: rgba(0,0,0, 0.05);
}

/* forms **************/

/*
  Migration support: Bootstrap 4.x -> 5.x
  Form layout classes were removed in Bootstrap 5.x. Suggested replacvement is: Grid system + Utilities.
  -> Adding manual backward compatibility here
*/

.form-group {
  margin-bottom: 1rem;
}

.form-inline .form-control {
  display: inline-block;
  width: auto;
  vertical-align: middle;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

/* end: compatibility support*/

.dermatest main .form-control {
    font-weight: 250;
}
.dermatest form label {
    margin-bottom: 0;
}

form.ng-touched .ng-valid[required], form.ng-touched .ng-valid.required, form.ng-touched .multiRequired.ng-valid  {
  border-left: 5px solid #42A948; /* green */
}

form.ng-touched .ng-invalid:not(form)  {
  border-left: 5px solid #a94442; /* red */
}

form.ng-dirty button.form-control {
  box-shadow: rgba(0, 0, 0, 0.5) 1px 5px 5px 2px;
}

.dermatest .entity .dirty, .dermatest .entity.dirty {
    text-shadow: rgba(0, 0, 0, 0.5) 3px 3px 5px;
    font-style: italic;
}

.dermatest .dt-form-control {
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
}

.dermatest .form-group label {
    margin-bottom: 0;
}

/* checkbox with label */
.dermatest .form-check-aligned {
    padding-left: calc(var(--bs-gutter-x) * .5);
}
.dermatest .form-check-aligned input {
    padding-left: 0;
    margin-left: 0;
}
.dermatest .form-check input[type="checkbox"] {
    width: 1.5em;
    height: 1.5em;
}
.dermatest .form-check label {
    padding-left: 10px;
    position: relative;
    top: 0.2em;
}

.dermatest .input-group-append input[type="checkbox"] {
    width: 2em;
    margin-left: 1em;
}

/* accordions **************/

.dermatest .accordion {
    --bs-accordion-border-width: 0;
    --bs-accordion-btn-icon-width: 0;
    --bs-accordion-btn-padding-y: .375rem;
    --bs-accordion-active-bg: var(--bs-accordion-bg);
    --bs-accordion-btn-color: #1891ca;
    --bs-accordion-active-color: #1891ca;
    --bs-accordion-btn-focus-box-shadow: 0;
}

.dermatest .accordion-header {
    padding: .2em 0 0 0;
    background: transparent;
    border-color: #b0b0b0;
}
.dermatest .accordion-header .accordion-button {
    display: block;
}
.dermatest .accordion-header .valid {
    color: #33aa33;
}
.dermatest .accordion-header .invalid {
    color: #a94442;
}
.dermatest .accordion-header .oi {
    transform: rotate(0deg);
    transition: 0.3s ease-in-out;
}

.dermatest .accordion-header .oi.card-open {
    transform: rotate(90deg);
    transition: 0.3s ease-in-out;
}

.dermatest .accordion-body {
    border-top: #b0b0b0 1px solid;
    border-bottom: #b0b0b0 1px solid;
    overflow-y: visible;
}

/* tooltips *************/

.dt-tooltip {
    animation-name: delayedFadeIn;
    animation-duration: 0.8s;
}

.dt-tooltip .tooltip-inner {
    background-color: rgba(227, 245, 253, 0.9);
    border: 1px solid #e0e0e0;
    color: black;
    font-size: 0.8em;
    box-shadow: rgba(0, 0, 0, 0.4) 1px 5px 6px 0;
}
.dt-tooltip .arrow::before {
    display: none;
}

@keyframes delayedFadeIn {
    0% {opacity: 0;}
    75% {opacity: 0;}
    100% {opacity: 1;}
}

/* editor  **************/

.ck.ck-reset_all .ck.ck-toolbar-container {
    position: absolute;
}
ckeditor .ck.ck-editor__editable_inline > :first-child {
    margin-top: 0;
}

ckeditor h1::before {
    content: 'a ';
}
ckeditor h2::before {
    content: 'a.b ';
}
ckeditor h3::before {
    content: 'a.b.c ';
}
ckeditor h4::before {
    content: 'a.b.c.d ';
}
ckeditor h1::before, h2::before, h3::before, h4::before {
    font-size: 0.8em;
}

ckeditor .ck-content > div, ckeditor .ck-content > p {
    margin-bottom: 1rem;
    text-align: justify;
}

ckeditor .dt-field {
    background-color: #b0b0b0;
}

ckeditor div.Literatur_SIB::before {
    content: '•';
    padding: 5px;
}

ckeditor p.context-ref {
    display: none;
    margin-bottom: 0;
}
main.adminuser ckeditor p.context-ref {
    display: block;

}

// the class nogrid is used on tables in HTML templates to hide the borders,
// but the CustomTableClasses plugin upcasts the class to the enclosing figure element. 
figure.nogrid table {
    border: hidden !important;
}
figure.nogrid td {
    border: hidden !important;
}

ckeditor .dt-quotation {
    margin-left: 4em;
    margin-right: 4em;
    font-style: italic;
}

/* template editing **************/

.ace_editor {
    font-size: 1rem;
    margin-bottom: 20px;
}

.ace_editor.ace_readonly .ace_content {
    opacity: 0.7;
}

/* spinner **************/

.dt-spinner-container {
    position: fixed;
    top: 40%;
    left: 50%;
    width: 20rem;
    height: 20rem;
    margin-left: -10rem;
    margin-right: -10rem;
    z-index: 100,
}
.dt-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 30px;
    height: 30px;
    margin-left: -15px;
    margin-right: -15px;
    border-radius: 15px;
    border: 3px solid #e50000;
    animation: spinner_pulsate 1.5s ease-out;
    animation-iteration-count: infinite;
}

@keyframes spinner_pulsate {
    0% {
      transform: scale(.5);
      opacity: 0.0;
    }
    50% {
      opacity: 0.7;
    }
    100% {
      transform: scale(2.5);
      opacity: 0;
    }
}

/* notification **************/

.dt-notify-container {
    position: fixed;
    top: 40%;
    left: 50%;
    width: 25rem;
    height: 25rem;
    margin-left: -10rem;
    margin-right: -10rem;
    z-index: 100,
}
.dt-notify {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 30px;
    height: 30px;
    margin-left: -15px;
    margin-right: -15px;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 15px;
    border: 2px solid #e50000;
    color: #e50000;
    animation: notify_pulsate 0.7s ease-out;
}

.dt-notify.error {
    background-color: #e50000;
    color: white;
}

@keyframes notify_pulsate {
    0% {
      transform: scale(1.5);
      opacity: 0.0;
    }
    50% {
      opacity: 0.7;
    }
    80% {
        opacity: 0.7;
    }
    100% {
      transform: scale(2.0);
      opacity: 0;
    }
}

.dt-warning {
    color: #ead300;
}

/* editable label **************/

.dermatest .dt-editable-label .label {
    display: inline-block;
    padding: 0.2em 0.1em;
}

.dermatest .dt-editable-label:hover {
    box-shadow: 0 0 0 1px #ced4da;
}

.dt-edit-marker {
    font-size: 0.6em;
    padding-right: 0.3em;
    vertical-align: text-top;
    margin-top: 0.3em;;
}

/* removable tags **************/

.dermatest .dt-tag {
    border: 1px solid #b0b0b0;
    border-radius: 0.75em;
    padding: 0 0.5em;
    margin-right: 0.5em;
    margin-bottom: 0.5em;
}
.dermatest .dt-tag .oi {
    margin-left: 0.5em;
    font-size: 0.7em;
    top: -0.1em;
}
.dermatest .dt-tag a {
    color: #1891ca;
    text-decoration: none;
}

.dermatest .dt-tag.dt-tag-primary, .dermatest .dt-tag-primary .dt-tag {
    background-color: rgba(0,0,0, 0.08);
}

/* special **************/

.dt-eukv::before {
    content: '('
}
.dt-eukv::after {
    content: ')'
}
.dt-inci-route::before {
    content: '['
}
.dt-inci-route::after {
    content: ']'
}

.dt-external-link {
    font-size: 0.9em;
}

.dt-external-link span.oi {
    font-size: 0.8em;
}

.dt-placeholder {
    color: #b0b0b0;
    font-style: italic;
}
input::placeholder {
    color: #b0b0b0;
    font-style: italic;
}

.dermatest .value, .dermatest .operator, .dermatest .separator {
    padding-left: 0.4em;
}
.dermatest .unit {
    padding-left: 0.2em;
    color: #a0a0a0;
}
